@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif !important;
}

input:checked + div {
  @apply border-blue-500;
}
input:checked + div svg {
  @apply block;
}

@layer base {
  a {
    @apply underline;
    @apply text-blue-600;
  }
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}
